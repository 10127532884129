import {add, getUnixTime, isBefore, isEqual, parse, parseISO, sub,} from "date-fns";
import {reverse} from "lodash";
import {format, utcToZonedTime} from "date-fns-tz";

const ExcelJS = require("exceljs");

export const convertHexToRGB = (hex) => {
    // check if it's a rgba
    if (hex.match("rgba")) {
        let triplet = hex.slice(5).split(",").slice(0, -1).join(",");
        return triplet;
    }

    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");

        return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",");
    }
};

// export function debounce(func, wait, immediate) {
//   var timeout;
//   return function () {
//     var context = this,
//       args = arguments;
//     clearTimeout(timeout);
//     timeout = setTimeout(function () {
//       timeout = null;
//       if (!immediate) func.apply(context, args);
//     }, wait);
//     if (immediate && !timeout) func.apply(context, args);
//   };
// }

export const isMobile = () => {
    if (window) {
        return window.matchMedia(`(max-width: 767px)`).matches;
    }
    return false;
};

export const isMdScreen = () => {
    if (window) {
        return window.matchMedia(`(max-width: 1199px)`).matches;
    }
    return false;
};

// export function getTimeDifference(date) {
//   let difference = differenceInSeconds(new Date(), date);

//   if (difference < 60) return `${Math.floor(difference)} sec`;
//   else if (difference < 3600) return `${Math.floor(difference / 60)} min`;
//   else if (difference < 86400) return `${Math.floor(difference / 3660)} h`;
//   else if (difference < 86400 * 30)
//     return `${Math.floor(difference / 86400)} d`;
//   else if (difference < 86400 * 30 * 12)
//     return `${Math.floor(difference / 86400 / 30)} mon`;
//   else return `${(difference / 86400 / 30 / 12).toFixed(1)} y`;
// }

export const flat = (array) => {
    let result = [];
    array.forEach((a) => {
        result.push(a);
        if (Array.isArray(a.children)) {
            result = result.concat(flat(a.children));
        }
    });
    return result;
};

const sanitizeData = (ObjectData) => {
    return Object.entries(ObjectData).reduce((sanitized, [key, value]) => {
        let sanitizedValue;
        if (key.match(/PIT|Rake_Thickness|Rotating_Pressure|FE000|SCEW_R_MINUS_L/)) {
            sanitizedValue = value.toFixed(1);
        } else if (key.match(/LIT|M_MudLevel|Flow_/)) {
            sanitizedValue = value.toFixed(2);
        } else {
            sanitizedValue = Math.round(value);
        }
        sanitized[key] = sanitizedValue;
        return sanitized;
    }, {});
};

// funcion para actualizar los valores de los tag
export const updateTagValue = (oldValue, newValue) => {
    // Early return optimizado
    if (!newValue) return oldValue;

    // Destructuring optimizado
    const {queryTime, ...tagValues} = newValue;

    // Sanitizar datos una sola vez y guardar resultado
    const sanitizedData = sanitizeData(tagValues);

    // Pre-alocamos los arrays para mejor rendimiento
    const areas = Object.keys(oldValue);
    const result = {};
    const areaCount = areas.length;

    // Bucle optimizado para áreas
    for (let i = 0; i < areaCount; i++) {
        const area = areas[i];
        const tags = oldValue[area];
        const tagKeys = Object.keys(tags);
        const tagCount = tagKeys.length;
        const updatedTags = {};

        // Bucle optimizado para tags
        for (let j = 0; j < tagCount; j++) {
            const tag = tagKeys[j];
            const oldTag = tags[tag];

            // Actualizar solo si hay un nuevo valor
            updatedTags[tag] = {
                ...oldTag,
                value: sanitizedData[tag] ?? oldTag.value
            };
        }

        result[area] = updatedTags;
    }

    // Agregar queryTime al final
    result.queryTime = queryTime;

    return result;
};

export const exportTailingData = (data, average) => {
    if (data) {
        const wb = new ExcelJS.Workbook();
        const sheet = wb.addWorksheet("Produccion C4");
        sheet.columns = [
            {
                header: "Fecha",
                key: "DateTime",
                width: 25,
                style: {alignment: {horizontal: "center", vertical: "middle"}},
            },
            {
                header: "densidad L1",
                key: "densidad_l1",
                width: 15,
                style: {alignment: {horizontal: "center", vertical: "middle"}},
            },
            {
                header: "solidos L1",
                key: "solidos_l1",
                width: 15,
                style: {alignment: {horizontal: "center", vertical: "middle"}},
            },
            {
                header: "malla L1",
                key: "malla_l1",
                width: 15,
                style: {alignment: {horizontal: "center", vertical: "middle"}},
            },
            {
                header: "densidad L2",
                key: "densidad_l2",
                width: 15,
                style: {alignment: {horizontal: "center", vertical: "middle"}},
            },
            {
                header: "solidos L2",
                key: "solidos_l2",
                width: 15,
                style: {alignment: {horizontal: "center", vertical: "middle"}},
            },
            {
                header: "malla L2",
                key: "malla_l2",
                width: 15,
                style: {alignment: {horizontal: "center", vertical: "middle"}},
            },
        ];
        let rowcount = 1;
        data.forEach((row) => {
            sheet.addRow([
                format(new Date(row.DateTime), "yyyy-MM-dd HH:mm:ss", {
                    timeZone: "America/Bogota",
                }),
                row.densidad_l1,
                row.solidos_l1,
                row.malla_l1,
                row.densidad_l2,
                row.solidos_l2,
                row.malla_l2,
            ]);
            rowcount++;
        });

        sheet.addRow([]);
        rowcount++;
        sheet.addRow([]);
        rowcount++;
        sheet.mergeCells(`A${rowcount}:E${rowcount}`);
        sheet.getCell(`A${rowcount}`).value = "Promedios de turno";
        sheet.addRow([null, "solidos L1", "malla L1", "solidos L2", "malla L2"]);
        sheet.addRow([
            "Promedio Primer turno",
            average.firstShiftAverage.densidad_l1,
            average.firstShiftAverage.solidos_l1,
            average.firstShiftAverage.malla_l1,
            average.firstShiftAverage.densidad_l2,
            average.firstShiftAverage.solidos_l2,
            average.firstShiftAverage.malla_l2,
        ]);
        sheet.addRow([
            "Promedio Segundo turno",
            average.secondShiftAverage.densidad_l1,
            average.secondShiftAverage.solidos_l1,
            average.secondShiftAverage.malla_l1,
            average.secondShiftAverage.densidad_l2,
            average.secondShiftAverage.solidos_l2,
            average.secondShiftAverage.malla_l2,
        ]);
        sheet.addRow([
            "Promedio Tercer turno",
            average.thirdShiftAverage.densidad_l1,
            average.thirdShiftAverage.solidos_l1,
            average.thirdShiftAverage.malla_l1,
            average.thirdShiftAverage.densidad_l2,
            average.thirdShiftAverage.solidos_l2,
            average.thirdShiftAverage.malla_l2,
        ]);

        const date = data[0].DateTime.slice(0, 10);
        wb.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = `datos c4 ${date}`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    }
};

export const exportJS = (data) => {
    if (data) {
        const wb = new ExcelJS.Workbook();
        const sheet = wb.addWorksheet("Produccion C3");
        const sumLineOne = data.reduce((acc, curr) => acc + curr.WCT1741, 0);
        const sumLineTwo = data.reduce((acc, curr) => acc + curr.WCT2741, 0);
        sheet.columns = [
            {
                header: "Fecha",
                key: "Fecha",
                width: 20,
                style: {alignment: {horizontal: "center", vertical: "middle"}},
            },
            {
                header: "033-001",
                key: "033-001",
                width: 10,
                style: {alignment: {horizontal: "center", vertical: "middle"}},
            },
            {
                header: "033-002",
                key: "033-002",
                width: 10,
                style: {alignment: {horizontal: "center", vertical: "middle"}},
            },
            {
                header: "Tn/Filtro L1 - 3 Filtros",
                key: "Tn/Filtro L1",
                width: 20,
                style: {alignment: {horizontal: "center", vertical: "middle"}},
            },
            {
                header: "Tn/Filtro L2 - 3 Filtros",
                key: "Tn/Filtro L2",
                width: 20,
                style: {alignment: {horizontal: "center", vertical: "middle"}},
            },
            {
                header: "Tn/Filtro L1 - 4 Filtros",
                key: "Tn/Filtro L1",
                width: 20,
                style: {alignment: {horizontal: "center", vertical: "middle"}},
            },
            {
                header: "Tn/Filtro L2 - 4 Filtros",
                key: "Tn/Filtro L2",
                width: 20,
                style: {alignment: {horizontal: "center", vertical: "middle"}},
            },
            {
                header: "Tn/Filtro L1 - 5 Filtros",
                key: "Tn/Filtro L1",
                width: 20,
                style: {alignment: {horizontal: "center", vertical: "middle"}},
            },
            {
                header: "Tn/Filtro L2 - 5 Filtros",
                key: "Tn/Filtro L2",
                width: 20,
                style: {alignment: {horizontal: "center", vertical: "middle"}},
            },
            {
                header: "Produccion L1",
                key: "Produccion L1",
                width: 15,
                style: {alignment: {horizontal: "center", vertical: "middle"}},
            },
            {
                header: "Produccion L2",
                key: "Produccion L2",
                width: 15,
                style: {alignment: {horizontal: "center", vertical: "middle"}},
            },
        ];
        data.forEach((row) => {
            sheet.addRow([
                format(new Date(row.DateTime), "yyyy-MM-dd HH:mm", {
                    timeZone: "America/Bogota",
                }),
                Math.round(row.WIT1741),
                Math.round(row.WIT2741),
                Math.round(row.WIT1741 / 3),
                Math.round(row.WIT2741 / 3),
                Math.round(row.WIT1741 / 4),
                Math.round(row.WIT2741 / 4),
                Math.round(row.WIT1741 / 5),
                Math.round(row.WIT2741 / 5),
                Math.round(row.WCT1741),
                Math.round(row.WCT2741),
            ]);
        });

        sheet.addConditionalFormatting({
            ref: `D2:I${data.length + 1}`,
            rules: [
                {
                    priority: 2,
                    type: "cellIs",
                    operator: "greaterThan",
                    formulae: [159],
                    style: {
                        fill: {
                            pattern: "solid",
                            type: "pattern",
                            bgColor: {argb: "ffa4ffa4"},
                        },
                    },
                },
                {
                    priority: 1,
                    type: "cellIs",
                    operator: "lessThan",
                    formulae: [159],
                    style: {
                        fill: {
                            type: "pattern",
                            pattern: "solid",
                            bgColor: {argb: "ffda9694"},
                        },
                    },
                },
            ],
        });
        sheet.addRow([
            ,
            ,
            ,
            ,
            ,
            ,
            ,
            ,
            ,
            "Total",
            Math.round(sumLineOne),
            Math.round(sumLineTwo),
        ]);
        sheet.addRow([]);
        sheet.mergeCells(`A${data.length + 4}:G${data.length + 4}`);
        sheet.getCell(`A${data.length + 4}:G${data.length + 4}`).value =
            "Produccion L1 y L2 es la resta del totalizador indicado en la columna Hora con el de la hora anterior";
        const date = data[0].DateTime.slice(0, 10);
        wb.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = `datos c3 ${date}`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    }
};

const getCircuitAverage = (circuitData, circuitType) => {
    const minSpeed =
        circuitType === "design" ? 1450 : circuitType === "modified" ? 720 : 0;
    let count = 0;
    const circuitSum = circuitData.reduce(
        (acc, curr) => {
            if (curr.frec > minSpeed) {
                count++;
                return {
                    flujo: acc.flujo + curr.flujo,
                    frec: acc.frec + curr.frec,
                    sol: acc.sol + curr.sol,
                    ton_densimetro: acc.ton_densimetro + curr.ton_densimetro,
                    amp: acc.amp + curr.amp,
                };
            }
            return acc;
        },
        {flujo: 0, frec: 0, sol: 0, ton_densimetro: 0, amp: 0}
    );
    // console.log(circuitSum,count);
    return {
        flujo: Math.round(circuitSum.flujo / count),
        frec: Math.round(circuitSum.frec / count),
        sol: parseFloat((circuitSum.sol / count).toFixed(1)),
        ton_densimetro: Math.round(circuitSum.ton_densimetro / count),
        amp: parseFloat((circuitSum.amp / count).toFixed(1)),
    };
};

const calculateCircuitAverage = (shiftData, circuitType) => {
    const ciruitNumber = circuitType === "design" ? 1 : 2;
    const circuitData = shiftData.filter((row) => row.modo === ciruitNumber);
    if (circuitData.length === 0) {
        return null;
    }

    return getCircuitAverage(circuitData, circuitType);
};

const calculateShiftAverage = (lineData, shiftTime) => {
    if (lineData.length === 0) {
        return null;
    }
    //horas de iniio del segundo y tercer turno
    const {secondshift, thirdshift} = shiftTime;

    let firstShiftArray = [];
    let secondShiftArray = [];
    let thirdShiftArray = [];
    //dividimos la data por turnos
    lineData.forEach((shiftRow) => {
        if (isBefore(parseISO(shiftRow.fecha), secondshift))
            firstShiftArray.push(shiftRow);
        else if (isBefore(parseISO(shiftRow.fecha), thirdshift))
            secondShiftArray.push(shiftRow);
        else thirdShiftArray.push(shiftRow);
    });
    // console.log(firstShiftArray,secondShiftArray,thirdShiftArray);
    const firstshiftAverage = {
        design: calculateCircuitAverage(firstShiftArray, "design"),
        modified: calculateCircuitAverage(firstShiftArray, "modified"),
    };
    const secondshiftAverage = {
        design: calculateCircuitAverage(secondShiftArray, "design"),
        modified: calculateCircuitAverage(secondShiftArray, "modified"),
    };
    const thirdShiftAverage = {
        design: calculateCircuitAverage(thirdShiftArray, "design"),
        modified: calculateCircuitAverage(thirdShiftArray, "modified"),
    };

    return {
        firstshift: firstshiftAverage,
        secondshift: secondshiftAverage,
        thirdshift: thirdShiftAverage,
    };
};
export const calculateProductionAverage = (shiftData, shiftTime) => {
    //si no hay nada de data (primer render) retornamos null
    if (shiftData === null || shiftData.length === 0) {
        return null;
    }
    //sacamos una copia destructurada para que no afecte el estado
    const reverseArrayData = reverse([...shiftData]);

    //dividimos la data por linea y descartamos los datos cuando esta parado los equipos
    const lineOne = reverseArrayData.filter(
        (row) => row.linea === 1 && row.modo !== 3
    );
    const lineTwo = reverseArrayData.filter(
        (row) => row.linea === 2 && row.modo !== 3
    );
    //calculamos los promedios
    const lineOneAverage = calculateShiftAverage(lineOne, shiftTime);
    const lineTwoAverage = calculateShiftAverage(lineTwo, shiftTime);

    return [lineOneAverage, lineTwoAverage];
};

const separateDatabyShift = (data, shiftTime) => {
    if (data === null || data === undefined) {
        return null;
    }
    const {secondshift, thirdshift} = shiftTime;
    let firstShiftArray = [];
    let secondShiftArray = [];
    let thirdShiftArray = [];

    data.forEach((shiftRow) => {
        if (isBefore(parseISO(shiftRow.DateTime), secondshift))
            firstShiftArray.push(shiftRow);
        else if (isBefore(parseISO(shiftRow.DateTime), thirdshift))
            secondShiftArray.push(shiftRow);
        else thirdShiftArray.push(shiftRow);
    });
    // console.log(firstShiftArray,secondShiftArray,thirdShiftArray);
    return {
        firstShiftData: firstShiftArray,
        secondShiftData: secondShiftArray,
        thirdShiftData: thirdShiftArray,
    };
};

const calculateTailingShiftAverage = (shiftAverage) => {
    if (shiftAverage.length === 0) {
        return [];
    }

    let denL1count = 0;
    let maL1count = 0;
    let denL2count = 0;
    let maL2count = 0;

    const sumShift = shiftAverage.reduce(
        (acc, curr) => {
            let solL1 = acc.solidos_l1;
            let denL1 = acc.densidad_l1;
            let mallaL1 = acc.malla_l1;
            let solL2 = acc.solidos_l2;
            let mallaL2 = acc.malla_l2;
            let denL2 = acc.densidad_l2;

            if (curr.densidad_l1 !== null) {
                denL1count++;
                denL1 = acc.densidad_l1 + curr.densidad_l1;
                solL1 = acc.solidos_l1 + curr.solidos_l1;
            }
            if (curr.malla_l1 !== null) {
                maL1count++;
                mallaL1 = acc.malla_l1 + curr.malla_l1;
            }
            if (curr.malla_l2 !== null) {
                maL2count++;
                mallaL2 = acc.malla_l2 + curr.malla_l2;
            }
            if (curr.densidad_l2 !== null) {
                denL2count++;
                denL2 = acc.densidad_l2 + curr.densidad_l2;
                solL2 = acc.solidos_l2 + curr.solidos_l2;
            }

            return {
                solidos_l1: solL1,
                malla_l1: mallaL1,
                solidos_l2: solL2,
                malla_l2: mallaL2,
                densidad_l1: denL1,
                densidad_l2: denL2,
            };
        },
        {
            solidos_l1: 0,
            malla_l1: 0,
            solidos_l2: 0,
            malla_l2: 0,
            densidad_l1: 0,
            densidad_l2: 0,
        }
    );

    return {
        solidos_l1:
            sumShift.solidos_l1 !== 0
                ? parseFloat((sumShift.solidos_l1 / denL1count).toFixed(1))
                : "-",
        densidad_l1:
            sumShift.densidad_l1 !== 0
                ? parseFloat((sumShift.densidad_l1 / denL1count).toFixed(1))
                : "-",
        solidos_l2:
            sumShift.solidos_l2 !== 0
                ? parseFloat((sumShift.solidos_l2 / denL2count).toFixed(1))
                : "-",
        densidad_l2:
            sumShift.densidad_l2 !== 0
                ? parseFloat((sumShift.densidad_l2 / denL2count).toFixed(1))
                : "-",
        malla_l1:
            sumShift.malla_l1 !== 0
                ? parseFloat((sumShift.malla_l1 / maL1count).toFixed(1))
                : "-",
        malla_l2:
            sumShift.malla_l2 !== 0
                ? parseFloat((sumShift.malla_l2 / maL2count).toFixed(1))
                : "-",
    };
};
export const calculateTailingAverage = (data, shiftTime) => {
    if (data === null || data === undefined) return null;

    const {firstShiftData, secondShiftData, thirdShiftData} =
        separateDatabyShift(data, shiftTime);
    const firstShiftAverage = calculateTailingShiftAverage(firstShiftData);
    const secondShiftAverage = calculateTailingShiftAverage(secondShiftData);
    const thirdShiftAverage = calculateTailingShiftAverage(thirdShiftData);

    return {firstShiftAverage, secondShiftAverage, thirdShiftAverage};
};

export const getShiftTimeData = (shiftTime) => {
    const currentZonedTime = utcToZonedTime(new Date(), "America/Bogota");
    const dailyShiftStartTime = parse("08:00:00", "HH:mm:ss", currentZonedTime);

    const shiftStartTime = isBefore(currentZonedTime, dailyShiftStartTime)
        ? sub(dailyShiftStartTime, {days: 1})
        : dailyShiftStartTime;

    const pickerZonedTime = utcToZonedTime(shiftTime, "America/Bogota");
    const pickerShiftStartTime = parse("08:00:00", "HH:mm:ss", pickerZonedTime);

    const isPastShift = isBefore(pickerShiftStartTime, shiftStartTime);

    const adjustedShiftStartTime =
        isPastShift || isEqual(shiftStartTime, pickerShiftStartTime)
            ? pickerShiftStartTime
            : sub(pickerShiftStartTime, {days: 1});

    const secondShift = add(adjustedShiftStartTime, {hours: 8});
    const thirdShift = add(adjustedShiftStartTime, {hours: 16});
    const endShift = add(adjustedShiftStartTime, {days: 1});

    const unixStartTime = getUnixTime(adjustedShiftStartTime);
    const unixEndTime = getUnixTime(endShift);

    return {
        ispast: isPastShift,
        startshift: adjustedShiftStartTime,
        secondshift: secondShift,
        thirdshift: thirdShift,
        endshift: endShift,
        unixstartshift: unixStartTime,
        unixendshift: unixEndTime,
    };
};

export const getStatus = (value, alarms) => {
    if (value < 2) return "stopped";
    if (value > alarms.alarma_h_h) return "HH";
    if (value > alarms.alarma_h) return "H";
    if (value < alarms.alarma_l_l) return "LL";
    if (value < alarms.alarma_l) return "L";
    return "normal";
};

export const getMachineTransmiterStatus = (value, alarms, machine) => {
    if (machine < 2 || value < 2) return "stopped";
    if (value > alarms.alarma_h_h) return "HH";
    if (value > alarms.alarma_h) return "H";
    if (value < alarms.alarma_l_l) return "LL";
    if (value < alarms.alarma_l) return "L";
    return "normal";
};
