import Loadable from "app/components/Loadable";
import {lazy} from "react";

const TagConfiguration = Loadable(lazy(() => import("./TagConfiguration")));

const maintenanceRoutes = [
    {
        path: "/maintenance/tag",
        element: <TagConfiguration/>,
        auth: [1],
    },
];

export default maintenanceRoutes;
