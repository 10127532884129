import AuthGuard from "app/auth/AuthGuard";
import NotFound from "app/views/sessions/NotFound";
import sessionRoutes from "app/views/sessions/SessionRoutes";
import {Navigate} from "react-router-dom";
import LayoutContainer from "./components/layouts/LayoutContainer";
import adminRoutes from "./views/admin/adminRoutes";
import plantsRoutes from "./views/plants/PlantsRoutes";
import operationRoutes from "./views/operation/operationRoutes";
import homeRoutes from "./views/home/homeRoutes";
import maintenanceRoutes from "./views/maintenance/maintenanceRoutes";
import dashboardRoutes from "./views/dashboard/dashboardRoutes";
import consumptionRoutes from "./views/consumption/consumptionRoutes";

const routes = [
    {
        element: (
            <AuthGuard>
                <LayoutContainer/>
            </AuthGuard>
        ),
        children: [
            ...homeRoutes,
            ...plantsRoutes,
            ...adminRoutes,
            ...operationRoutes,
            ...maintenanceRoutes,
            ...dashboardRoutes,
            ...consumptionRoutes,

        ],
    },
    ...sessionRoutes,
    {path: "/", element: <Navigate to="/home"/>},
    {path: "*", element: <NotFound/>},
];

export default routes
