import Loadable from "app/components/Loadable";
import {lazy} from "react";

const CrushingPlant = Loadable(lazy(() => import("./CrushingPlant")));
const ConcentratorPlant = Loadable(lazy(() => import("./ConcentratorPlant")));
const FilteringPlant = Loadable(lazy(() => import("./FilteringPlant")));
const TailingPlant = Loadable(lazy(() => import("./TailingPlant")));
const GeneralView = Loadable(lazy(() => import("./GeneralView")));

const plantsRoutes = [
    {
        path: "/plant/crushing",
        element: <CrushingPlant/>,
        auth: [1, 2, 3, 11],
    },
    {
        path: "/plant/concentrator",
        element: <ConcentratorPlant/>,
        auth: [1, 2, 3, 12],
    },
    {
        path: "/plant/filtering",
        element: <FilteringPlant/>,
        auth: [1, 2, 3, 13],
    },
    {
        path: "/plant/tailing",
        element: <TailingPlant/>,
        auth: [1, 2, 3, 14],
    },
    {
        path: "/general",
        element: <GeneralView/>,
        auth: [1, 2, 3, 4, 6],
    },
];

export default plantsRoutes;
