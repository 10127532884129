import Loadable from "../../components/Loadable";
import {lazy} from "react";

const ConcentratorConsumption = Loadable(lazy(() => import("./ConcentratorConsumption")));
const FilteringHumidity = Loadable(lazy(() => import("./FilteringHumidity")));
const TailingConsumption = Loadable(lazy(() => import("./TailingConsumption")));

const consumptionRoutes = [
    {
        path: "/consumption/concentrator",
        element: <ConcentratorConsumption/>,
        auth: [1, 2],
    }, {
        path: "/consumption/filtering",
        element: <FilteringHumidity/>,
        auth: [1, 2],
    }, {
        path: "/consumption/tailing",
        element: <TailingConsumption/>,
        auth: [1, 2],
    },
]

export default consumptionRoutes