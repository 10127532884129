import Loadable from "app/components/Loadable";
import {lazy} from "react";

const Home = Loadable(
    lazy(() => import("./Home"))
);

const homeRoutes = [
    {
        path: "/home",
        element: <Home/>,
        auth: [1, 2, 3, 4, 6, 11, 12, 13, 14],
    },
]

export default homeRoutes