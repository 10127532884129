import {useRoutes} from "react-router-dom";
import {AuthProvider} from "./contexts/JWTAuthContext.js";
import {SettingsProvider} from "./contexts/SettingsContext";
import {CssBaseline} from "@mui/material";
import Theme from "./components/themes/Theme";
import routes from "./routes";

const App = () => {
    const content = useRoutes(routes);

    return (
        <SettingsProvider>
            <Theme>
                <CssBaseline/>
                <AuthProvider>{content}</AuthProvider>
            </Theme>
        </SettingsProvider>
    );
};

export default App;
