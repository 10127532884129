import useSettings from 'app/hooks/useSettings';
import {Layouts} from "./Layouts";
import CustomSuspense from "../CustomSuspense";

const LayoutContainer = (props) => {
    const {settings} = useSettings();
    const Layout = Layouts[settings.activeLayout];

    return (
        <CustomSuspense>
            <Layout {...props} />
        </CustomSuspense>
    );
};

export default LayoutContainer;
