import {CircularProgress} from '@mui/material';
import {Box, styled} from '@mui/system';

const StyledLoading = styled('div')(() => ({
    padding: '1rem 2rem',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
        width: 'auto',
        height: '50px',
    },
    '& .circleProgress': {
        position: 'absolute',
        left: -8,
        right: 0,
        top: 'calc(50% - 35px)',
    },

}));

const Loading = () => {
    return (
        <StyledLoading>
            <Box position="relative">
                <img src="/assets/images/shp/logo_black.svg" alt=""/>
                <CircularProgress className="circleProgress" size={65} color='gray' thickness={2.3}/>
            </Box>
        </StyledLoading>
    );
};

export default Loading;
