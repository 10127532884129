import {themes} from '../themes/initThemes';
import layout1Settings from './Layout1/Layout1Settings';

// UPDATE BELOW CODE
// DOC http://demos.ui-lib.com/matx-react-doc/layout.html
export const MatxLayoutSettings = {
    activeLayout: 'layout1', // layout1, layout2
    activeTheme: 'blue', // View all valid theme colors inside themes/themeColors.js
    perfectScrollbar: true,

    themes: themes,
    layout1Settings, // open Layout1/Layout1Settings.js

    secondarySidebar: {
        show: false,
        open: false,
        theme: 'slateDark1', // View all valid theme colors inside themes/themeColors.js
    },
    // Footer options
    footer: {
        show: true,
        fixed: false,
        theme: 'slateDark1', // View all valid theme colors inside themes/themeColors.js
    },
};  
